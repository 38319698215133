@import "https://cdn.rawgit.com/google/code-prettify/master/styles/desert.css";
$lightGray: #fafafa;
$medBlue: #9baecf;
$char: #292929;


body {

}


.code {
  font-family: 'Source Code Pro';
  background: $char;
  color: $lightGray;
  font-size: 14px;
  padding: 0;
  padding: 5px;
  &:before {
    display: block;
    width: calc(100%);
    margin-left: -5px;
    margin-top: -5px;
    margin-right: 20px;
    padding: 3px;
    text-transform: uppercase;
    content: attr(data-lang);
    background: $medBlue;
  }
  .o {
    color: orange;
  }
  .w {
    color: white;
  }
}