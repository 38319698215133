.body-container {
    position: relative;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
    color: #333333;
    display: flex;
    text-align: left;
    text-size-adjust: 100%;
    box-sizing: border-box;
}

.content-navbar {
    border-top: 1px solid lightgray;
}

.nav-container {
    display: flex;
    background-color: rgb(250, 250, 250);
    overflow: hidden;
    width: 270px;
    height: 100vh;
    flex-direction: column;
    position: sticky;
    top: 0;
    text-align: left;
}

.list-component {
    list-style: none inside none;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    display: list-item;
    box-sizing: border-box;
}


.component-name {
    cursor: pointer;
    color: #333333;
    margin: 0;
    padding: 12.5px 20px;
    display: flex;
    justify-content: space-between;
    font-family: sans-serif;
    font-size: 1em;
    text-transform: none;
}

.image-logo {
    width: 100%;
}

.component-name.active {
    background-color: lightblue;
}

.content-container {
    width: 80%;
}

.component-name.active,
.component-name:hover {
  background-color: #ededed;
  color: #32329f;
}

.heading-text {
    font-family: Montserrat,sans-serif;
    font-weight: 400;
    font-size: 1.85714em;
    line-height: 1.6em;
    color: #32329f;
    width: 100%;
    padding: 40px 40px;
}

.heading-content {
    width: 100%;
    padding: 0px 40px;
    line-height: 1.5em;
}

.authentication-block {
    width: 100%;
    padding: 40px 40px;
    display: block;
    text-align: left;
    box-sizing: border-box;

}

.auth-heading {
    font-size: 1.57143em;
    line-height: 1.6em;
    color: black;
}

.table-body {
    box-sizing: border-box;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    word-break: keep-all;
    border-collapse: collapse;
    border-spacing: 0;
}

.table-block {
    display: block;
    width: 100%;
    overflow: auto;
    word-break: keep-all;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border-color: gray;
    text-indent: initial;
    text-rendering: optimizeSpeed !important;

}

.table-row {
    border-top: 1px solid #ccc;
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    font-size: 14px;

}

.table-heading {
    font-weight: bold;
    padding: 6px 13px;
    border: 1px solid #ddd;
    display: table-cell;
    vertical-align: inherit;
}

.table-data {
    padding: 6px 13px;
    border: 1px solid #ddd;
    display: table-cell;
    vertical-align: inherit;
}